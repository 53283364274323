'use client'

import { FunctionComponent, useContext, useMemo, useState } from 'react'
import useCategoryList from '../../../core/hook/useCategoryList'
import { CartContext } from '../../context/CartContext'
import { useTranslation } from '../../context/ConfigContext'
import { Button } from '../Button/Button'
import { CartListSmall } from '../CartList/CartList'
import { getAreAllItemsStillValidOnCart } from '../CheckoutWorkflow/getIsItemStillValidOnCart'
import { Modal } from './Modal'
import CartGtmEvent from '../Cart/CartGtmEvent'
import useCurrentLocale from '../../../core/hook/useCurrentLocale'
import FormattedPrice from '../FormattedPrice/FormattedPrice'

const OUR_ICONIC_JEWELRY_CATEGORY_ID = '75'

const ModalCart: FunctionComponent<ModalInterface> = ({ uuid, onClose }) => {
  const { cart } = useContext(CartContext)
  const t = useTranslation()
  const locale = useCurrentLocale()
  const [isLoadingFirst, setIsLoadingFirst] = useState(false)
  const [isLoadingSecond, setIsLoadingSecond] = useState(false)
  const [isLoadingThird, setIsLoadingThird] = useState(false)

  const data = useCategoryList({ categoryIds: [OUR_ICONIC_JEWELRY_CATEGORY_ID] })
  const urlPathIconicJewery = data?.categoryList?.[0]?.url_path

  const areAllItemsStillValidOnCart = useMemo(() => getAreAllItemsStillValidOnCart(cart), [cart])


  return (
    <>
      <CartGtmEvent event={'view_cart'} />
      <Modal
        className='cart-container'
        uuid={uuid}
        dark={false}
        anchor='right'
        showLogo={false}
        onClose={onClose}
        title={t('My Cart', {}, false)}
        center={cart.items.length < 1}>
        {cart.items.length < 1 ? (
          <>
            <h4 className='title-h4'>{t('You have no items in your shopping cart.')}</h4>
            <div className='button-group'>
              <Button
                classname='black'
                primary
                label={t('minicart-continue-text', {}, false)}
                onClick={onClose}
              />
              <Button
                isLoading={isLoadingThird}
                classname='border-black'
                primary
                onClick={() => setIsLoadingThird(true)}
                href={urlPathIconicJewery ? `/${urlPathIconicJewery}` : ''}
                style='light'
                label={t('minicart-discover-collection-text-generic', {}, false)}
              />
            </div>
          </>
        ) : (
          <div className='modal-cart'>
            <div className='summary-cart'>
              <CartListSmall cart={cart} allowRemove />
            </div>
            <div className='summary'>
              <div className='summary-total'>
                <p className='total-title'>{t('Subtotal', {}, true)}</p>
                <p className='total' aria-label='total'>
                  <FormattedPrice 
                    price={cart.prices.grand_total.value}
                    currency={cart.prices.grand_total.currency}
                  />
                </p>
              </div>
              {cart.prices.applied_taxes?.length > 0 && (
                <p className='small'>{t('cart-totals-tva-information', {}, true)}</p>
              )}
              <p className='small'>{t('cart-totals-shipping-information', {}, true)}</p>
              <div className='button-group'>
                <Button
                  isLoading={isLoadingFirst}
                  onClick={() => setIsLoadingFirst(true)}
                  classname={`black ${!areAllItemsStillValidOnCart ? 'disabled' : ''}`}
                  primary
                  style='dark'
                  label={t('Go to Checkout', {}, false)}
                  href={`/${locale}/checkout`}
                  disabled={!areAllItemsStillValidOnCart}
                />
                <Button
                  isLoading={isLoadingSecond}
                  onClick={() => setIsLoadingSecond(true)}
                  classname='white'
                  primary
                  style='very-light'
                  label={t('View and Edit Cart', {}, false)}
                  href={`/${locale}/cart`}
                />
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

export default ModalCart
