'use client'

import { FunctionComponent, useContext, useEffect } from 'react'
import { CartContext } from '../../context/CartContext'
import { gtmCategory, gtmPush } from '../../utils/gtmHelpers'

const CartGtmEvent: FunctionComponent<{ event: string }> = ({ event }) => {
  const { cart } = useContext(CartContext)

  useEffect(() => {
    const productItems: any[] = []

    cart.items.map((item) => {
      let obj = {}
      const gtmCategoryObj = gtmCategory(item?.product?.gtm_categories)
      const productSize = item?.configurable_options?.[0]?.value_label ?? null
      const sizeSku = productSize ? item?.product.sku + '-' + productSize : item?.product.sku

      obj['item_name'] = item?.product.gtm_name
      obj['item_id'] = item?.product.sku
      obj['price'] = item?.product.price_range.minimum_price.regular_price.value
      obj['item_brand'] = 'Messika'
      obj['index'] = item?.gtm_position ?? ''
      obj['quantity'] = item?.quantity
      obj['item_list_id'] = item.item_list_id ?? 'panier'
      obj['item_list_name'] = item.item_list_name ?? 'Panier'
      obj['item_variant'] = sizeSku
      obj = { ...obj, ...gtmCategoryObj }

      productItems.push(obj)
    })

    gtmPush({
      event: event,
      ecommerce: {
        value: cart.prices.grand_total.value,
        currency: cart.prices.grand_total.currency,
        items: productItems,
      },
    })
  }, [])

  return null
}

export default CartGtmEvent
