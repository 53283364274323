import { CategoryListDocument, CategoryFilterInput } from '../../queries/CategoryList.gql'
import { useQuery } from './useQuery'

type UseCategoryListFilters = {
  urlPath?: string
  categoryIds?: string[]
}

const useCategoryList = (filters: UseCategoryListFilters) => {
  const categoryFilterInput: CategoryFilterInput = {}

  if (filters.categoryIds) {
    categoryFilterInput.ids = { in: filters.categoryIds }
  }
  if (filters.urlPath) {
    categoryFilterInput.url_path = { eq: filters.urlPath }
  }

  const { data } = useQuery(CategoryListDocument, { variables: { filters: categoryFilterInput } })

  if (!data) {
    return null
  }

  return data
}

export default useCategoryList
